import { StringUtil } from "./StringUtil";

export class DateUtil {
    static isSameDate(d1: Date, d2: Date): boolean {
        if (!d1 && !d2) return true;
        if (!d1 || !d2) return false;
        if (d1.getFullYear() != d2.getFullYear()) return false;
        if (d1.getMonth() != d2.getMonth()) return false;
        if (d1.getDate() != d2.getDate()) return false;
        return true;
    }
    static addMilliseconds(date: Date, ms: number): Date {
        const v = date.valueOf() + ms;
        return new Date(v);
    }
    static elapsedMs(start: Date, stop?: Date): number {
        stop = stop || new Date();
        return stop.valueOf() - start.valueOf();
    }
    static elapsedSeconds(start: Date, stop?: Date): number {
        return DateUtil.elapsedMs(start, stop) / 1000;
    }
    static elapsed(start: Date, stop?: Date): string {
        var el = DateUtil.elapsedMs(start, stop);
        return DateUtil.msToTime(el);
    }
    static msToTime(s: number): string {
        const ms = s % 1000;
        s = (s - ms) / 1000;
        if (s === 0) return ms + ' ms';
        const secs = s % 60;
        s = (s - secs) / 60;
        if (s === 0) return secs + '.' + StringUtil.formatNumber(ms, 3) + ' s';
        const mins = s % 60;
        s = (s - mins) / 60;
        const h = s % 24;
        s = (s - h) / 24;
        if (s === 0) return StringUtil.formatNumber(h, 2) + ':' + StringUtil.formatNumber(mins, 2) + ':' + StringUtil.formatNumber(secs, 2);
        if (s < 10) return s + ' d ' + h + ' h';
        return s + ' d';
    }
    static toJavaScriptDate(value: string | Date | number): Date {
        if (!value) return null;
        if (typeof value === 'number')
            return new Date(value);
        if (typeof value === 'object')
            return value as Date;
        //check for .net json serialized date pattern
        const pattern = /Date\(([^)]+)\)/;
        const results = pattern.exec(value);
        if (results) {
            return new Date(parseFloat(results[1]));
        }
        //yyyy-mm-dd formats are used in ff
        const yearMonthDatePattern = /^(\d{4})-(\d{2})-(\d{2})$/;
        const ymdResult = yearMonthDatePattern.exec(value);
        if (ymdResult) {
            return new Date(parseInt(ymdResult[1]), parseInt(ymdResult[2]) - 1, parseInt(ymdResult[3]));
        }
        //date strings with time zones are ok
        const isoPattern = /[\+-]\d+:\d+$/
        if (isoPattern.test(value)) return new Date(value);
        //fallback to normal (newtonsoft.json format)
        if (!value.endsWith("Z"))
            value += "Z";
        return new Date(value);
    }
    static toJavaScriptDateNumber(value: string | Date | number): number {
        let date: Date = DateUtil.toJavaScriptDate(value);
        if (date) return date.getTime();
        return null;
    }
}
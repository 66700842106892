class TextRange {

}
interface IEElement {
    createTextRange(): TextRange;
}
export class Common {
    // Html encode text
    static htmlEncode(value:string) {
        return $("<div/>").text(value).html();
    }

    // Html decode text
    static htmlDecode(value:string) {
        return $("<div/>").html(value).text();
    }

    static toInt(value: any, defaultValue: number = 0): number {
        if (value > 0 || value < 0) return parseInt(value);
        return defaultValue;
    }
    // Colorize html text
    static colorEncodeHtml(htmlText) {
        let tags = ["img", "video", "source", "a"];

        let attributes = ["src", "width", "alt", "height", "preload", "controls", "poster", "type", "href"];

        for (var i = 0; i < tags.length; i++) {
            let tag = tags[i];
            htmlText = htmlText.replace("&lt;" + tag, "<span class=\"html-element\">&lt;" + tag + "</span>");
            htmlText = htmlText.replace("&lt;/" + tag + "&gt;", "<span class=\"html-element\">&lt;/" + tag + "&gt;</span>");
        }
        for (var i = 0; i < attributes.length; i++) {
            let attribute = attributes[i];
            htmlText = htmlText.replace(attribute + "=", "<span class='html-attribute'>" + attribute + "=</span>");
        }

        for (var i = 0; i < 10; i++) {
            htmlText = htmlText.replace("&quot;&gt;", "&quot;<span class=\"html-element\">&gt;</span>");
        }

        return htmlText;
    }

    // Select a text by class name
    static selectTextByClassName(className) {
        let doc = document
            , text = doc.getElementsByClassName(className)[0]
            , range, selection
            ;

        let ieElement = (doc.body as any) as IEElement;
        if (ieElement && ieElement.createTextRange) {
            range = ieElement.createTextRange();
            range.moveToElementText(text);
            range.select();
        } else if (window.getSelection) {
            selection = window.getSelection();
            range = document.createRange();
            range.selectNodeContents(text);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }

    // Show status message in imagevault
    static showMessage(message) {
        new (window as any).IVNotification(
            message,
            {
                showOverlay: false,
                duration: 4000
            });
    }

    // Set cookie
    static setCookie(name: string, value: string | object, expireDays: number) {
        if (!value) {
            value = "";
        } else if (typeof (value) !== "string") {
            value = JSON.stringify(value);
        }

        var d = new Date();
        d.setTime(d.getTime() + (expireDays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    }

    static getJsonCookie(cname: string, fallback: any = {}): any {
        var cookieData = Common.getCookie(cname);
        if (!cookieData) return fallback;
        try {
            return JSON.parse(cookieData);
        } catch {
            return fallback;
        }
    }
    // Get cookie
    static getCookie(cname: string): string {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    static getBrowser() {
        var userAgent = navigator.userAgent;

        var tem;
        var match = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(match[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
            return { 'browser': "IE", 'version': (tem[1] || "") };
        }
        if (match[1] === "Chrome") {
            tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) return { 'browser': tem.slice(1)[0].replace("OPR", "Opera"), 'version': tem.slice(1)[1] }
        }
        match = match[2] ? [match[1], match[2]] : [navigator.appName, navigator.appVersion, "-?"];
        if ((tem = userAgent.match(/version\/(\d+)/i)) != null) match.splice(1, 1, tem[1]);
        return { 'browser': match[0], 'version': match[1] };
    }

    static getBrowserName() {
        var browser = Common.getBrowser().browser;
        var browserName = "";
        if (browser === "Chrome" || browser === "Safari")
            browserName = "webkit";
        else if (browser === "Firefox")
            browserName = "mozilla";
        else if (browser === "Opera")
            browserName = "opera";

        return browserName;
    }

    static isValidDate(dateString) {
        // First check for the pattern
        var regex_date = /^\d{4}\-\d{1,2}\-\d{1,2}$/;

        if (!regex_date.test(dateString)) {
            return false;
        }

        // Parse the date parts to integers
        var parts = dateString.split("-");
        var day = parseInt(parts[2], 10);
        var month = parseInt(parts[1], 10);
        var year = parseInt(parts[0], 10);

        // Check the ranges of month and year
        if (year < 1000 || year > 3000 || month == 0 || month > 12) {
            return false;
        }

        var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Adjust for leap years
        if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
            monthLength[1] = 29;
        }

        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
    }
    static uuidv4(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    static isValidUrl(url: string): boolean {
        if (!url) return false;
        var urlRegex = new RegExp(
            "^(?:\\w+:\\/\\/)?" +   //optional scheme
            "(?:[a-z0-9]+\\.)+" + //domain. 
            "[a-z0-9]{2,}" +     //topdomain
            "[^\\s]*" +           //optional trailing characters cannot be whitespace (path/query/hash)
            "$", "i"); //end of line, case insensitive
        return !!url.match(urlRegex);
    }

}

var MeridiumUtil;
if(exports){
    MeridiumUtil=exports;
} else if (!MeridiumUtil) {
    MeridiumUtil = {};
}

(function ($) {

    MeridiumUtil.htmlEncode = function (value) {
        return $('<div/>').text(value).html();
    };
    MeridiumUtil.htmlDecode = function (value) {
        return $('<div/>').html(value).text();
    };
	MeridiumUtil.endsWith = function(str, suffix) {
		return str.indexOf(suffix, str.length - suffix.length) !== -1;
	};
	MeridiumUtil.parseBool = function(val) {
		return /^true$/i.test(val);
	};
	MeridiumUtil.parseInt = function(val) {
		var i = parseInt(val);
		return i == NaN ? 0 : i;
	};

}(jQuery));


window.ImageVaultUi = window.ImageVaultUi || {};
require("./lib/jquery-1.6.1");
require("./jquery-extensions");
window.ImageVaultUi.Common = require("./src/common/common").Common;
window.ImageVaultUi.MetadataSymbol = require("./src/common/MetadataSymbol").MetadataSymbol;
window.ImageVaultUi.StringUtil = require("./src/common/StringUtil").StringUtil;
window.ImageVaultUi.ModalUtil = require("./src/common/ModalUtil").ModalUtil;
//jquery.ba-bbq requires this to be the window object
//https://webpack.js.org/loaders/imports-loader/
require("imports-loader?wrapper=window!./lib/jquery.ba-bbq");
require("./lib/jquery.url");
require("./lib/json2");
require("./lib/jquery-ui-1.11.4.custom/jquery-ui.min");
require("./lib/datepicker-de");
require("./lib/datepicker-sv");
require("./lib/datepicker-en");
require("./lib/jQuery.tmpl");
require("./lib/jquery.hoverIntent");
require("./lib/jquery.jscrollpane");
require("./lib/jquery.mousewheel");
require("./ImageVaultUi.Localization");
window._ = require("./lib/Underscore");
require("imports-loader?wrapper=window!./lib/masonry.pkgd");
//backbone requires underscore (window._) so we need to use the window wrapper in the imports-loader
window.Backbone = require("imports-loader?wrapper=window!./lib/Backbone");
window.MeridiumUtil = require("./lib/MeridiumUtil");
require("./lib/chosen/chosen.jquery.min");
require("./Notification");
window.Capabilities = require("./Capabilities");
require("./Global");
require("./lib/jquery.unobtrusive-ajax.custom");
require("./lib/placeholders");
require("./lib/photoswipe/photoswipe");
require("./lib/photoswipe/photoswipe-ui-default");
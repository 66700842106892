(function ($, ns) {
    var l = ns.Localization;
    if (!l) {
        console.log("Localization is not set up. Don't forget to include the link to the /Localization?culture=.. script before refering this script");
        //fallback to an uninitialized localization object
        l = ns.Localization = {};
    }
    var texts = l.Texts;
    var translate = function (msg, fallback) {
        if (!fallback) fallback = msg;
        var a = msg.split('.');
        var o = texts;
        var pos = 0;
        var key = a[pos];
        while (o && key) {
            o = o[key];
            if (!o) break;
            if (pos + 1 === a.length)
                return o;
            pos += 1;
            key = a[pos];
        }
        return fallback;
    };
    window.translate = l.translate = translate;
})(jQuery, window.ImageVaultUi = window.ImageVaultUi || {});
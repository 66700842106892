var Capabilities = function (config) {
    this.init(config);
    return this;
};
Capabilities.prototype = {
    // properties
    getScope: null,
    init: function (config) {
        //map external functions
        $.extend(this, config);
    }
};
var Scope = {
    Internal: { value: 0, name: "Internal" },
    External: { value: 1, name: "External" }
};
if(module) {
    module.exports=Capabilities;
    module.exports.Scope=Scope;
}

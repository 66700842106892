
String.prototype.reverse = function () {
    return this.split('').reverse().join('');
};

export class MetadataSymbol {
    currentRow: number;

    constructor(currentRow) {
        var self = this;
        this.currentRow = currentRow;

        // Events for buttons
        $(() => {
            $(".add-language").off().click(function () {
                self.addLanguageRow($(this));
                return false;
            });

            $(".remove-language").off().click(function () {
                self.removeLanguage($(this));
                return false;
            });

            $(".remove-symbol").off().click(function () {
                self.removeSymbol($(this));
                return false;
            });
        });
    }

    // Add symbol row
    addRow() {
        var self = this;

        // Html for new row
        var $newRow = $("<tr class='row row-" +
            this.currentRow +
            "'  data-row='" +
            this.currentRow +
            "'>" +
            "<td>" +
            "<input class='form-control' placeholder='Enter symbol key (classname)' name='metadataSymbols[" +
            this.currentRow +
            "].MetadataSymbolKey' type='text'/>" +
            " <a href='' class='remove-symbol btn btn-danger'>Remove symbol</a>" +
            "</td>" +
            "<td>" +
            "<table>" +
            "<tr>" +
            "<td>" +
            "<div class='language-wrapper'></div>" +
            "</td>" +
            "<td>" +
            "<input class='form-control' placeholder='Enter name' name='metadataSymbols[" +
            this.currentRow +
            "].MetadataSymbolDescriptions[0].Name' type='text'/>" +
            "</td>" +
            "<td><a href='' class='remove-language btn btn-danger'>Remove language</a></td>" +
            "</tr>" +
            "</table>" +
            "<a href='' class='add-language btn btn-default'>Add language</a>" +
            "</td>" +
            "</tr>");

        $(".metadata-symbol-table").append($newRow);

        // Add drop down (take first language drop down and modify)
        var $languagesDropDown = $(".language-dropdown").first().clone();
        $newRow.find(".language-wrapper").append($languagesDropDown);

        this.currentRow++;

        // Events for new buttons
        $(".add-language").off().click(function () {
            self.addLanguageRow($(this));
            return false;
        });

        $(".remove-symbol").off().click(function () {
            self.removeSymbol($(this));
            return false;
        });

        this.regenerateInputNumbers();

        return false;
    }

    // Add language row for symbol
    addLanguageRow($clickedElement) {
        var self = this;

        var $languageTable = $clickedElement.prev("table");
        var currentRow = $languageTable.closest("tr").attr("data-row");
        var languageRow = $languageTable.find("tr").length;

        // Language row html
        var $languageRow = $("<tr>" +
            "<td>" +
            "<div class='language-wrapper'></div>" +
            "</td>" +
            "<td><input class='form-control' placeholder='Enter name' name='metadataSymbols[" +
            currentRow +
            "].MetadataSymbolDescriptions[" +
            languageRow +
            "].Name' type='text'/> " +
            "</td>" +

            "<td><a href='' class='remove-language btn btn-danger'>Remove language</a></td>" +
            "</tr>");

        $languageTable.append($languageRow);

        // Add drop down (take first language drop down and modify)
        var $languagesDropDown = $(".language-dropdown").first().clone();

        $languageRow.find(".language-wrapper").append($languagesDropDown);

        $(".remove-language").off().click(function () {
            self.removeLanguage($(this));
            return false;
        });

        this.regenerateInputNumbers();

        return false;
    }

    // Remove symbol row
    removeSymbol($clickedElement) {
        $clickedElement.closest(".row").remove();
        this.regenerateInputNumbers();
        return false;
    }

    // Remove language row
    removeLanguage($clickedElement) {
        $clickedElement.closest("tr").remove();
        this.regenerateInputNumbers();
        return false;
    }

    // Regenerate input numbers
    regenerateInputNumbers() {
        var rowCounter = 0;

        // Loop throught every row
        $(".row").each(() => {
            var $row = $(".row").eq(rowCounter);

            // Set new class
            $row.attr("class", "row row-" + rowCounter);

            // Set new value for data row
            $row.attr("data-row", + rowCounter);

            // Find all inputs and selects and replace number
            $row.find("input, select").each((inputIndex) => {
                var $input = $row.find("input, select").eq(inputIndex);
                var currentInputName = $input.attr("name");
                var replacedName = currentInputName.replace(new RegExp("[0-999]"), rowCounter.toString());

                $input.attr("name", replacedName);
            });

            var subRowCounter = 0;

            // Loop throught subrows
            $row.find("table tr").each(() => {
                var $subRow = $row.find("table tr").eq(subRowCounter);

                // Replace last number in name
                $subRow.find("input, select").each((subRowInputIndex) => {
                    var $input = $subRow.find("input, select").eq(subRowInputIndex);
                    var currentInputName = $input.attr("name").toString();

                    // First reverse to replace last number
                    var replacedName = currentInputName.reverse()
                        .replace(new RegExp("[0-999]"), subRowCounter.toString().reverse()).reverse();

                    $input.attr("name", replacedName);
                });

                subRowCounter++;
            });

            rowCounter++;
        });
    }
}

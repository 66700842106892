import { DateUtil } from "./DateUtil";

export class StringUtil {
    static bytesToString(bytes: number): string {
        if (!bytes) return "0 bytes";
        if (bytes === 1) return "1 byte";
        if (bytes < 1000)
            return bytes + " bytes";

        let num1 = bytes / 10.24;
        const chArray: string[] = ["k", "M", "G", "T", "P", "E"];
        let index = 0;
        while (true) {
            const num2 = Math.floor(Math.log10(Math.round(num1 / 100.0))) + 1.0;
            if (index < chArray.length - 1 && num2 > 3) {
                num1 /= 1024;
                ++index;
            }
            else
                break;
        }
        const digits = Math.max(5 - (Math.floor(Math.log10(num1)) + 1.0), 0);
        return (num1 / 100.0).toFixed(digits) + " " + chArray[index] + "b";
    }
    static formatDateRange(start: Date, stop: Date): string {
        let commonFormat: string = null;
        let uniqueFormat: string;
        if (start.getFullYear() != stop.getFullYear()) {
            uniqueFormat = "yyyy-MM";
        }
        else {
            commonFormat = "yyyy";
            if (start.getMonth() != stop.getMonth()) {
                uniqueFormat = "MM-dd";
            }
            else {
                commonFormat += "-MM";
                if (start.getDay() != stop.getDay()) {
                    uniqueFormat = "dd HH:00";
                }
                else {
                    commonFormat += "-dd";
                    if (start.getHours() != stop.getHours() || start.getMinutes() != stop.getMinutes()) {
                        uniqueFormat = "HH:mm";
                    }
                    else {
                        commonFormat += " HH:mm";
                        if (start.getSeconds() != stop.getSeconds()) {
                            uniqueFormat = "ss";
                        }
                        else {
                            commonFormat += ":ss";
                            uniqueFormat = null;
                        }
                    }
                }
            }

        }

        let common = "";
        if (commonFormat) {
            common = StringUtil.formatDate(start, commonFormat);
        }

        let unique = "";
        if (uniqueFormat) {
            unique = StringUtil.formatDate(start, uniqueFormat) + "-" + StringUtil.formatDate(stop, uniqueFormat);
        }
        return (common + " " + unique).trim();
    }
    private static readonly dateTimeFormat = new Intl.DateTimeFormat(undefined, { dateStyle: "short", timeStyle: "short" });
    private static readonly dateFormat = new Intl.DateTimeFormat(undefined, { dateStyle: "short" });
    static formatLocalDate(date:Date|string):string {
        if (!date) return "";
        var dd = DateUtil.toJavaScriptDate(date);
        //        return dd.toLocaleDateString() + " " + dd.toLocaleTimeString();
        return this.dateFormat.format(dd);
    }
    static formatLocalDateAndTime(date: Date | string): string {
        if (!date) return "";
        var dd = DateUtil.toJavaScriptDate(date);
        //        return dd.toLocaleDateString() + " " + dd.toLocaleTimeString();
        return this.dateTimeFormat.format(dd);
    }
    static formatDate(date: Date | string, format: string): string {
        if (!date) return "";
        var dd = DateUtil.toJavaScriptDate(date);

        format = format.replace(/yyyy/g, dd.getFullYear().toString());
        format = format.replace(/MM/g, StringUtil.formatNumber(dd.getMonth() + 1, 2));
        format = format.replace(/dd/g, StringUtil.formatNumber(dd.getDate(), 2));
        format = format.replace(/HH/g, StringUtil.formatNumber(dd.getHours(), 2));
        format = format.replace(/mm/g, StringUtil.formatNumber(dd.getMinutes(), 2));
        format = format.replace(/ss/g, StringUtil.formatNumber(dd.getSeconds(), 2));
        format = format.replace(/fff/g, StringUtil.formatNumber(dd.getMilliseconds(), 3));
        format = format.replace(/ff/g, StringUtil.formatNumber(Math.round(dd.getMilliseconds() / 10), 2));
        format = format.replace(/f/g, Math.round(dd.getMilliseconds() / 100).toString());
        return format;
    }
    static formatNumber(a: number, size: number) {
        var s = a + "";
        while (s.length < size) s = "0" + s;
        return s;
    }
    /**
     * Formats the supplied string and inserts supplied argument at numbered positions using {index} syntax
     * @param s String to format. Can contain variable placeholders on the format {index}, where index refers to the argument that it will be replaced with
     * @param args list of arguments to use as replacements in the string
     */
    static formatString(s: string, ...args: string[]): string {
        if (!s) return s;
        return s.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] != 'undefined'
                ? args[number]
                : match
                ;
        });
    }
    static expandVariables(s: string, context: any): string {
        if (!context) return s;
        return s.replace(/\${([^\}]+)}/g, function (match, key) {
            return typeof context[key] != 'undefined'
                ? context[key]
                : match
                ;
        });
    }

    static removeEmptyLines(s: string): string {
        return s.replace(/^\s*[\r\n]/gm, '');
    }
    static compare(a: string, b: string): number {
        if (a) {
            a = a.toLocaleLowerCase();
        }
        if (b) {
            b = b.toLocaleLowerCase();
        }
        if (a > b) return 1;
        if (b > a) return -1;
        return 0;
    }
}
export class ModalUtil {
    /**
     * Disable body scroll by setting position to fixed and top to negative scroll position
     * @returns true if body scroll was disabled, false if it was already disabled
     */
    static disableBodyScroll():boolean {
        if(document.body.style.position === "fixed") return false;
        const originalWidth = document.body.offsetWidth;
        const scrollY = window.scrollY;
        document.body.style.position = "fixed";
        const scrollBarWidth = document.body.offsetWidth - originalWidth;
        document.body.style.top = `-${scrollY}px`;
        // document.body.style.paddingRight = `${scrollBarWidth}px`;
        document.body.style.width = `calc(100vw - ${scrollBarWidth}px)`;
        return true;
    }
    /**
     * Enables body scroll. Must be preceeded by a successful disableBodyScroll call
     * @returns true if body scroll was enabled, false if it was already enabled
     */
    static enableBodyScroll():boolean {
        if(document.body.style.position !== "fixed") return false;
        const scrollY = document.body.style.top;
        document.body.style.position = "";
        document.body.style.top = "";
        // document.body.style.paddingRight = "";
        document.body.style.width = "";
        window.scrollTo(0, parseInt(scrollY || "0") * -1);
        return true;
    }
}
